import { PersonRemove } from "@mui/icons-material";
import {
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import Gravatar from "react-gravatar";
import { useTranslation } from "react-i18next";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { useProfile } from "../../providers/UserProfileProvider";
import {
  useCreateAdministratorMutation,
  useDeleteAdministratorMutation,
  useGetAdministratorsQuery,
} from "../../services/administrators";
import UserPicker from "../widgets/UserPicker";
import LoadingError from "./LoadingError";
import Loading from "../Loading";

export default function AdministratorsMembers() {
  const { data, error, isLoading } = useGetAdministratorsQuery();
  const [removeAdministrator] = useDeleteAdministratorMutation();
  const [addAdministrator] = useCreateAdministratorMutation();
  const { showAlert } = useSnackbar();
  const { user } = useProfile();
  const { showConfirmationDialog } = useConfirmationDialog();
  const { t } = useTranslation();

  if (isLoading) return <Loading />;
  else if (error) return <LoadingError error={error} />;

  const handleRemoveUser = async (user) => {
    const mesg = t("Remove user {{user}} from administrators?", {
      user: user.name || user.email,
    });
    if (await showConfirmationDialog(mesg)) {
      await removeAdministrator(user.id)
        .unwrap()
        .catch((e) => {
          console.error(e);
          showAlert(t("Failed to save"));
        });
    }
  };

  const addMember = async ({ sub, name, email }) => {
    if (data.map((m) => m.sub).includes(sub)) {
      showAlert(t("User is already an administrator"), "info");
      return false;
    }

    try {
      await addAdministrator({ sub, name, email }).unwrap();
      return true;
    } catch (e) {
      console.error(e);
      showAlert(t("Failed to add"));
      return false;
    }
  };

  return (
    <>
      <Typography variant="h6">{t("Designated Users")}</Typography>
      <div className="ui-user-picker">
        <UserPicker onUserPicked={addMember} onlyExisting={true} />
      </div>
      <List dense>
        {data.map((member) => (
          <ListItem key={member.id} secondaryAction={
            <IconButton
              onClick={() => handleRemoveUser(member)}
              disabled={member.sub.toLowerCase() === user.sub.toLowerCase()}
              className="ui-remove-administrator-button"
            >
              <PersonRemove />
            </IconButton>
          }>
            <ListItemAvatar>
              <Gravatar
                email={member.email}
                size={40}
                style={{ borderRadius: 20, marginRight: "10px" }}
              ></Gravatar>
            </ListItemAvatar>
            <ListItemText primary={member.name} secondary={member.email} />
          </ListItem>
        ))}
      </List>
    </>
  );
}
