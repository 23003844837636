import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProjectState from "../../model/ProjectState";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import Stack from '@mui/material/Stack'
import { IconButton } from '@mui/material'
import { Check, Close, Edit } from '@mui/icons-material'

export default function RefNumber() {
  const { t } = useTranslation();

  const {
    project: { refNumber, state},
    saveProject,
  } = useProjectApi();

  const [refNumberValue, setRefNumberValue] = useState(refNumber)
  const [isRefNumberEditing, setIsRefNumberEditing] = useState(false)

  const handleRejectSaving = () => {
    setRefNumberValue(refNumber);
    setIsRefNumberEditing(false)
  }

  const handleSave = () => {
    if (refNumberValue) {
      saveProject({
        refNumber: refNumberValue,
        state: state || ProjectState.NEW,
      });
    }
    setIsRefNumberEditing(false)
  }

  const handleEdit = () => {
    setIsRefNumberEditing(true)
  }

  return (
    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginBottom: '1rem' }}>
      <Typography>{t("Reference Number (e.g. Case ID)* -")}</Typography>
      <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
        {isRefNumberEditing ?
          <>
            <TextField
              required
              variant="standard"
              value={refNumberValue}
              onChange={(event) => {
                setRefNumberValue(event.target.value);
              }}
              className="ui-refnumber"
              sx={{ width: 60 }}
            />
            <Stack direction="row">
              <IconButton onClick={() => handleSave()} color="success"><Check /></IconButton>
              <IconButton onClick={() => handleRejectSaving()} color="error"><Close /></IconButton>
            </Stack>
          </> :
          <>
            <strong>{refNumber}</strong>
            <IconButton onClick={() => handleEdit()} color="primary"><Edit /></IconButton>
          </>
        }
      </Stack>
    </Box>
  );
}
