import { Alert, Collapse, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import { useEffect, useRef, useState } from "react";
import Gravatar from "react-gravatar";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { useProfile } from "../../providers/UserProfileProvider";
import authService from "../../services/authService";
import { useRespondInvitationMutation } from "../../services/organizations";
import { useGetProjectsQuery } from "../../services/projects";

const AboutToLooseExistingProjectsAccessWarning = ({ user, organization }) => {
  const { t } = useTranslation();
  const { data } = useGetProjectsQuery({
    ownerUser: user.sub,
    ownerOrganization: organization.id,
  });
  return (
    <Collapse in={data && data.length != 0} sx={{ mt: 2 }}>
      <Alert severity="warning">
        {t("organizations.aboutToLooseExistingProjectsAccess", {
          organizationName: organization.name,
        })}
      </Alert>
    </Collapse>
  );
};

export default function UserProfile() {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { showConfirmationDialog } = useConfirmationDialog();
  const [respondInvitation] = useRespondInvitationMutation();
  const { t } = useTranslation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const { user, organization, invitation } = useProfile();

  useEffect( () => {
    if (invitation) {
      const fetch = async () => {
        const accept = await showConfirmationDialog({
          title: t("organizations.youAreInvited"),
          message: (
            <>
              {t("organizations.doYouWantToJoin", {
                organizationName: invitation.name,
              })}
              <AboutToLooseExistingProjectsAccessWarning
                user={user}
                organization={organization}
              />
            </>
          ),
          canDismiss: false,
          buttons: [t("Yes"), t("No")],
        });
        await respondInvitation({
          organizationId: invitation.id,
          memberId: invitation.memberId,
          accept: accept,
        });
        if (accept) window.location.reload(false);
      }

      fetch()
    }
  }, []);

  return (
    <Stack direction="row" spacing={2}>
      <div className="ui-profile-menu">
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Gravatar
            email={user.email}
            size={40}
            style={{ borderRadius: 20, marginRight: "10px" }}
          />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={handleClose}
                      component="a"
                      target="_blank"
                      href={authService.getAccountUrl()}
                    >
                      {t("Account")}
                    </MenuItem>
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to="/usersettings"
                    >
                      {t("Settings")}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={handleClose}
                      component={Link}
                      to="/logout"
                    >
                      {t("Logout")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
