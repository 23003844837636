import Grid from "@mui/material/Grid2";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import ProjectState from "../../model/ProjectState";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import ProjectInfoTutorial from "../tutorial/ProjectIntoTutorial";
import DataUsageConsent from "../widgets/DataUsageConsent";
import MultiToothPicker from "../widgets/MultiToothPicker";

export default function TeethMapping() {
  const { t } = useTranslation();

  const {
    project: { teethUnderRepair, teethSettings },
    saveProject,
  } = useProjectApi();

  function setProjectInfo(data) {
    saveProject({
      ...data,
      state: ProjectState.NEW,
    });
  }

  return (
    <>
      <ProjectInfoTutorial />
      <Grid container spacing={3}>
        <Grid container size={12} direction="column">
          <Box>
            <MultiToothPicker
              selectedTeeth={teethUnderRepair}
              teethSettings={teethSettings}
              onChange={setProjectInfo}
              label={t("Prepped teeth")}
            />
          </Box>
        </Grid>
        <Grid size={12}>
          <DataUsageConsent />
        </Grid>
      </Grid>
    </>
  );
}
