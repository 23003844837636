import React from 'react'

export const TabPanel = (props) => {
  const { children, value, activeTab, ...other } = props;

  if (value !== activeTab) {
    return null
  }

  return (
    <div
      role="tabpanel"
      hidden={value !== activeTab}
      id={`tabpanel-${value}`}
      aria-labelledby={`simple-tab-${value}`}
      {...other}
    >
      {children}
    </div>
  );
}
