import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmail from "validator/lib/isEmail";
import { searchUsers } from "../../services/apiService";
import { makeUserDisplayLabel } from "../../utils/users";

export default function UserPicker({ onUserPicked, onlyExisting }) {
  const [users, setUsers] = useState([]);
  const [pickedUser, setPickedUser] = useState(null);
  const [userToAdd, setUserToAdd] = useState(null);
  const [addInProgress, setAddInProgress] = useState(false);
  const { t } = useTranslation();

  // To workaround autocomplete clear issue
  // See https://stackoverflow.com/questions/59790956/material-ui-autocomplete-clear-value
  const [key, setKey] = useState(0);

  const handlePicked = (option) => {
    if (typeof option === "object") {
      setUserToAdd(option);
      setPickedUser(option);
    }
  };

  const filter = _.debounce((value) => {
    if (onlyExisting) {
      setUserToAdd(null);
      return;
    }

    setUserToAdd(isEmail(value) ? { email: value } : null);

    // In case an email is typed, force the value into the AutoComplete.
    // Otherwise, it won't be possible to clear the AutoComplete when the
    // user presses the "Add" button (because the value isn't changed)
    if (isEmail(value)) setPickedUser(value);
  }, 300);

  const pickUser = async () => {
    const { sub, name, email } = userToAdd;
    setAddInProgress(true);
    if (await onUserPicked({ sub, name, email })) {
      setUserToAdd(null);
      setPickedUser(null);
      setKey(key + 1);
    }
    setAddInProgress(false);
  };

  useEffect(() => {
    searchUsers().then((resp) => {
      if (resp.data) setUsers(resp.data);
    });
  }, []);

  return (
    <Grid container spacing={1} margin={1}>
      <Grid>
        <Autocomplete
          key={key}
          disableClearable
          autoSelect
          freeSolo={!onlyExisting}
          clearOnBlur={false}
          readOnly={addInProgress}
          variant="outlined"
          size="small"
          sx={{ width: "40ch" }}
          options={users.map((user) => ({
            label: makeUserDisplayLabel(user),
            ...user,
          }))}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("User name or email address")}
              onChange={(e) => filter(e.target.value)}
              onKeyDown={(e) => {
                if (e.key == "Enter" && userToAdd) pickUser();
              }}
            />
          )}
          onChange={(_, v, r) => {
            handlePicked(v);
          }}
          value={pickedUser}
          isOptionEqualToValue={(option, value) => option.email === value.email}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.email}>
                {option.label}{" "}
                {option.label != option.email ? `(${option.email})` : ""}
              </li>
            );
          }}
        />
      </Grid>

      <Grid alignItems="center" style={{ display: "flex" }}>
        <Button
          variant="contained"
          disabled={!userToAdd || addInProgress}
          onClick={pickUser}
        >
          {t("Add")}
        </Button>
      </Grid>
      <Grid alignItems="center" style={{ display: "flex" }}>
        {addInProgress ? (
          <CircularProgress size={24}></CircularProgress>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}

