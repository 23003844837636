import { FormControl, IconButton, List, ListItem, MenuItem, Select } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useSettings } from '../../providers/UserProfileProvider';
import { getToothLabel } from '../../utils/ToothNotation';
import { Clear } from '@mui/icons-material';
import { Typography, Box } from '@mui/material';
import {ToothType} from "../../model/Project";

const toothTypesMap = {
  [ToothType.NATURAL_TOOTH]: 'Natural tooth (prep)',
  [ToothType.IMPLANT]: 'Implant',
  [ToothType.PONTIC]: 'Pontic',
};
const brandArr = ['Straumann'];
const connectionTypeArr = ['rc', 'nc', 'sc', 'wn', 'rn', 'nnc', 'wt', 'rt', 'nt', 'rb', 'wb'];

const ToothTypeSelect = (props) => {
  const {isInBridge, tooth, onChangeType, toothType = ''} = props

  const [type, setType] = useState(toothType);
  const [brand, setBrand] = useState('');
  const [connectionType, setConnectionType] = useState('');

  const handleChangeType = (event) => {
    onChangeType(tooth, event.target.value);
    setType(event.target.value);
    if (event.target.value !== ToothType.IMPLANT) {
      setBrand('');
      setConnectionType('');
    }
  };

  const handleChangeBrand = (event) => {
    setBrand(event.target.value);
  };

  const handleChangeConnectionType = (event) => {
    setConnectionType(event.target.value);
  };

  useEffect(() => {
    if (!isInBridge && type === ToothType.PONTIC) {
      setType('')
    }
  }, [isInBridge, type])

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', flex: 1, gap: 2}}>
      <FormControl fullWidth size="small">
        <Select
          displayEmpty
          value={type}
          onChange={handleChangeType}
        >
          <MenuItem disabled value="">
            {/* <Box sx={{ color: 'rgb(0 0 0 / 40%)' }}>Select</Box> */}
            Select
          </MenuItem>
          {Object.keys(toothTypesMap).filter((key) => isInBridge || key !== ToothType.PONTIC).map((key) => (
            <MenuItem key={key} value={key}>
              {toothTypesMap[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {type === ToothType.IMPLANT &&
        <>
          <FormControl fullWidth size="small">
            <Select
              disabled
              value={brand || brandArr[0]}
              onChange={handleChangeBrand}
            >
              {/* <MenuItem disabled value=""> */}
              {/*   <Box sx={{ color: 'rgb(0 0 0 / 40%)' }}>Select</Box> */}
              {/* </MenuItem> */}
              {brandArr.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth size="small">
            <Select
              displayEmpty
              placeholder={'Connection type'}
              value={connectionType}
              onChange={handleChangeConnectionType}
            >
              <MenuItem disabled value="">
                {/* <Box sx={{ color: 'rgb(0 0 0 / 40%)' }}>Connection type</Box> */}
                Connection type
              </MenuItem>
              {connectionTypeArr.map((item) => (
                <MenuItem key={item} value={item}>
                  {item.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      }
    </Box>
  )
}

const toothColWidth = 80

export default function ToothSettings(props) {
  const { selectedTeeth, selectedBridges, teethSettings, onDelete, onChangeType, jaw } = props;
  const { notation } = useSettings();

  const filteredSelectedTeeth = useMemo(() => {
    if (jaw === 'upper'){
      return selectedTeeth.filter((tooth) => tooth <= 16)
    }

    return selectedTeeth.filter((tooth) => tooth > 16)
  }, [selectedTeeth])

  return (
    <>
      <Box sx={{ padding: '0.5rem 1rem', background: 'rgb(0 0 0 / 10%)' }}>
        <Typography variant="h6">{jaw === 'upper' ? 'Upper Arch' : 'Lower Arch'}</Typography>
      </Box>

      {filteredSelectedTeeth.length ?
        <List>
          <ListItem sx={{ gap: 3 }}>
            <Box sx={{ width: toothColWidth }}>
              <Typography>Tooth</Typography>
            </Box>
            <Box>
              <Typography>Type</Typography>
            </Box>
          </ListItem>
          {filteredSelectedTeeth.map((tooth) =>
            <ListItem
              key={tooth}
              secondaryAction={
                <IconButton edge="end" onClick={() => onDelete(tooth)}>
                  <Clear />
                </IconButton>
              }
              sx={{
                borderBottom: '1px solid #f0f0f0',
                gap: 3
              }}
            >
              <Box sx={{ width: toothColWidth }}>
                <Typography>{getToothLabel(tooth, notation)}</Typography>
              </Box>
              <ToothTypeSelect
                isInBridge={selectedBridges.includes(tooth) || selectedBridges.includes(tooth - 1)}
                tooth={tooth}
                toothType={teethSettings[tooth]?.type ?? ''}
                onChangeType={onChangeType}
              />
            </ListItem>
          )}
        </List>
        : <Typography variant="subtitle1" sx={{ textAlign: 'center', p: 2, color: 'rgb(0 0 0 / 50%)' }}>No teeth selected</Typography>
      }
    </>
  );
}
