import React, { useMemo } from 'react'
import { CircularProgress, Tab, Tabs, Tooltip, Box } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { useTranslation } from 'react-i18next'
import {toFdi} from "../../utils/ToothNotation";

export const TeethTabs = ({ data, value, onChange, statusField, tabTitle }) => {
  const { t } = useTranslation();

  const TabIcon = ({ status, name }) => {
    if (status === 'success') {
      return <CheckCircleIcon color="success" />
    }
    if (status === 'in progress') {
      return (
        <Tooltip title={t("toothRestorationsTooltips.inProgress", { name })}>
          <CircularProgress size="1.25rem" color="primary" />
        </Tooltip>
      )
    }
    if (status === 'failed') {
      return (
        <Tooltip title={t('toothRestorationsTooltips.failed', { name })}>
          <ErrorIcon color="error" />
        </Tooltip>
      )
    }
    if (status === 'pending') {
      return <HourglassEmptyIcon color="action" />
    }

    return <Box sx={{ width: '1.25rem' }} />
  }

  return (
    <Tabs value={value} onChange={onChange} orientation="vertical" sx={{ minWidth: '170px' }}>
      {data.map((item) => (
        <Tab
          key={item.tooth}
          icon={<TabIcon status={item[statusField]?.status} name={tabTitle} />}
          iconPosition="start"
          label={`${tabTitle} ${toFdi(item.tooth)}`}
          value={item.tooth}
          sx={{ gap: '0.5rem', minHeight: '3rem', justifyContent: 'flex-start', alignItems: 'center' }}
        />
      ))}
    </Tabs>
  )
}
