import React from 'react'
import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded'
import { DarkBox } from './DarkBox'


export const GenerationFailedMessage = ({ actions }) => {
  return (
    <DarkBox>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1.25rem',
        maxWidth: '550px',
        textAlign: 'left'
      }}>
        <Stack spacing={1}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 1 ,
            '& svg': {
              mt: '-3px'
            }
          }}>
            <WarningAmberRoundedIcon color="warning" />
            <Typography variant="body1">
              Margin Line Generation Failed
            </Typography>
            <WarningAmberRoundedIcon color="warning" />
          </Box>
          <Typography variant="body2" sx={{ lineHeight: 1.5 }}>
            We couldn’t generate the margin line due to an unexpected issue. <br/>
            Here’s how you can proceed: <br/>
            <strong>1. Review scan setup:</strong> Return to the Scans step, check your setup, and try again. <br/>
            <strong>2. Create manually:</strong> Open the preparation in the Margin Line Editor to manually draw the margin line
          </Typography>
        </Stack>
        {actions && <Stack direction="row" spacing={2}>{actions}</Stack>}
      </Box>
    </DarkBox>
  )
}
