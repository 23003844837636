import Grid from "@mui/material/Grid2";
import { useEffect, useMemo, useState } from 'react'
import useQuotaExceededDialog from '../../hooks/useQuotaExceededDialog'
import { useProjectApi } from '../../providers/ProjectApiProvider'
import { startCrownGeneration } from '../../services/apiService'
import useVisibilities from '../../hooks/useVisibilities'
import ProjectFilesViewer from './ProjectFilesViewer'
import { TeethTabs } from '../widgets/TeethTabs'
import { TabPanel } from '../widgets/TabPanel'

export default function CrownGeneration() {
  const {
    project: {
      id: projectId,
      teethUnderRepair,
      toothRestorations,
      ownerOrganization: organizationId,
    },
  } = useProjectApi();

  const { isVisible, setVisible } = useVisibilities({
    upperArch: teethUnderRepair.some((x) => x <= 16),
    lowerArch: teethUnderRepair.some((x) => x > 16),
    ...Object.fromEntries(
      Object.keys(toothRestorations).map((t) => [`crown${t}`, true])
    ),
    ...Object.fromEntries(
      Object.keys(toothRestorations).map((t) => [`marginLine${t}`, true])
    ),
  });

  const showQuotaExceededDialog = useQuotaExceededDialog();

  const changeTab = (event, newValue) => {
    setValue(newValue);
  };

  const restorations = useMemo(() => {
    return Object.values(toothRestorations)
  }, [toothRestorations])

  const [value, setValue] = useState(restorations[0]?.tooth);

  useEffect(() => {
    const fetch = async () => {
      for (const x of Object.values(toothRestorations).filter(
        (x) => x.crownGeneration === null
      )) {
        try {
          await startCrownGeneration(projectId, x.tooth);
        } catch (e) {
          if (e.response.status === 429) {
            showQuotaExceededDialog(organizationId);
          }
        }
      }
    }

    fetch()
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid>
        <TeethTabs data={restorations} statusField="crownGeneration" tabTitle="Crown" value={value} onChange={changeTab} />
      </Grid>
      <Grid sx={{ flex: 1, minWidth: 0 }}>
        {restorations.map((restoration) => (
          <TabPanel activeTab={value} key={restoration.tooth} value={restoration.tooth}>
            <ProjectFilesViewer isVisible={isVisible} />
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
}
