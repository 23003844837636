import { useEffect, useMemo, useState } from 'react'
import arches from "./307px-Human_dental_arches.svg.png";
import Canvas from "./Canvas";

const archImage = (() => {
  const img = new Image();
  img.src = arches;
  return img;
})();

const extraWidth = 24
const unitColor = '#3370d4'
const bridgeColor = '#da0063'

const getScaledTeeth = (size, jaw) => {
  const upperRightTeeth = {
    1: { x: 26, y: 219, w: 47, h: 39, cx: 48, cy: 237, rx: 26, ry: 21 },
    2: { x: 29, y: 177, w: 48, h: 42, cx: 53, cy: 198, rx: 26, ry: 23 },
    3: { x: 40, y: 135, w: 44, h: 46, cx: 61, cy: 157, rx: 25, ry: 25 },
    4: { x: 54, y: 110, w: 35, h: 29, cx: 72, cy: 124, rx: 19, ry: 15 },
    5: { x: 65, y: 83, w: 37, h: 26, cx: 84, cy: 96, rx: 22, ry: 15 },
    6: { x: 81, y: 54, w: 29, h: 33, cx: 96, cy: 70, rx: 17, ry: 19 },
    7: { x: 103, y: 38, w: 31, h: 29, cx: 120, cy: 52, rx: 17, ry: 15 },
    8: { x: 132, y: 25, w: 37, h: 35, cx: 150, cy: 42, rx: 21, ry: 17 }
  };
  const lowerRightTeeth = {
    32: { x: 32, y: 270, w: 35, h: 37, cx: 49, cy: 291, rx: 21, ry: 21 },
    31: { x: 30, y: 308, w: 42, h: 44, cx: 52, cy: 331, rx: 21, ry: 25 },
    30: { x: 37, y: 352, w: 47, h: 46, cx: 61, cy: 376, rx: 23, ry: 26 },
    29: { x: 59, y: 395, w: 33, h: 31, cx: 75, cy: 411, rx: 19, ry: 15 },
    28: { x: 78, y: 422, w: 29, h: 29, cx: 92, cy: 436, rx: 18, ry: 16 },
    27: { x: 98, y: 444, w: 29, h: 29, cx: 111, cy: 458, rx: 14, ry: 16 },
    26: { x: 123, y: 454, w: 22, h: 28, cx: 134, cy: 469, rx: 11, ry: 16 },
    25: { x: 146, y: 461, w: 23, h: 25, cx: 156, cy: 475, rx: 12, ry: 14 }
  };

  const rightTeeth =
    jaw === "upper"
      ? upperRightTeeth
      : jaw === "lower"
      ? lowerRightTeeth
      : { ...upperRightTeeth, ...lowerRightTeeth };

  const reflect = ([num, tooth]) => [
    (num > 8 ? 49 : 17) - parseInt(num),
    {
      ...tooth,
      x: 169 * 2 - tooth.x - tooth.w,
      cx: 169 * 2 - tooth.cx
    },
  ];

  const teeth = {
    ...rightTeeth,
    ...Object.fromEntries(Object.entries(rightTeeth).map(reflect)),
  };

  // clip for lower jaw
  const offset = jaw === "upper" ? 0 : jaw === "lower" ? 247 : 0;
  Object.values(teeth).forEach((tooth) => {
    tooth.y -= offset;
    tooth.cy -= offset;
  });

  // Scale to current size
  Object.values(teeth).forEach((tooth) => {
    for (var measure in tooth) {
      tooth[measure] = (tooth[measure] * size) / 480;
    }
  });

  return teeth;
};

const getScaledBridges = (size, jaw) => {
  const upperRightBridges = {
    1: {cx: 16, cy: 212, r: 8},
    2: {cx: 24, cy: 170, r: 8},
    3: {cx: 37, cy: 126, r: 8},
    4: {cx: 48, cy: 98, r: 8},
    5: {cx: 64, cy: 72, r: 8},
    6: {cx: 92, cy: 42, r: 8},
    7: {cx: 120, cy: 22, r: 8},
    8: {cx: 170, cy: 12, r: 8},
  };
  const lowerRightBridges = {
    31: {cx: 20, cy: 308, r: 8},
    30: {cx: 26, cy: 360, r: 8},
    29: {cx: 44, cy: 412, r: 8},
    28: {cx: 62, cy: 440, r: 8},
    27: {cx: 84, cy: 468, r: 8},
    26: {cx: 112, cy: 488, r: 8},
    25: {cx: 140, cy: 498, r: 8},
    24: {cx: 170, cy: 500, r: 8},
  };

  const rightBridges =
    jaw === "upper"
      ? upperRightBridges
      : jaw === "lower"
        ? lowerRightBridges
        : { ...upperRightBridges, ...lowerRightBridges };

  const reflect = ([num, bridge]) => [
    (num > 8 ? 48 : 16) - parseInt(num),
    { ...bridge,
      // x: 169 * 2 - bridge.x - bridgeRadius * 2,
      cx: 169 * 2 - bridge.cx
    },
  ];

  const bridges = {
    ...rightBridges,
    ...Object.fromEntries(Object.entries(rightBridges).map(reflect)),
  };

  // clip for lower jaw
  const offset = jaw === "upper" ? 0 : jaw === "lower" ? 247 : 0;
  Object.values(bridges).forEach((bridge) => {
    // bridge.y -= offset;
    bridge.cy -= offset;
  });

  // Scale to current size
  Object.values(bridges).forEach((bridge) => {
    bridge.x = bridge.cx - bridge.r
    bridge.y = bridge.cy - bridge.r
    for (var measure in bridge) {
      bridge[measure] = (bridge[measure] * size) / 480;
    }
  });

  return bridges;
};

export default function ToothPicker({
  highlightedTeeth,
  availableBridges = [],
  onClick,
  onHover,
  highlightedBridges = [],
  selectedBridges= [],
  onBridgeClick,
  onBridgeHover,
  size,
  jaw,
}) {
  const [teeth, setTeeth] = useState({});
  const [bridges, setBridges] = useState({});

  useEffect(() => {
    setTeeth(getScaledTeeth(size, jaw));
    setBridges(getScaledBridges(size, jaw))
  }, [size, jaw]);

  const offset = jaw === "upper" ? 0 : jaw === "lower" ? 247 : 0;
  const clipSize = jaw === "upper" || jaw === "lower" ? 247 : 480;
  const canvasWidth = (307 * size) / 480 + extraWidth;
  const canvasHeight = (clipSize * size) / 480 + extraWidth;

  const highligthTooth = (ctx, tooth, color) => {
    var gradient = ctx.createRadialGradient(
      tooth.cx,
      tooth.cy,
      0,
      tooth.cx,
      tooth.cy,
      Math.max(tooth.rx, tooth.ry)
    );

    gradient.addColorStop(0, color + "f0");
    gradient.addColorStop(0.9, color + "90");
    gradient.addColorStop(1, "#FFFFFF00");

    ctx.beginPath();
    ctx.stroke();
    ctx.beginPath();
    ctx.fillStyle = gradient;
    ctx.ellipse(tooth.cx, tooth.cy, tooth.rx, tooth.ry, 0, 0, 2 * Math.PI);
    ctx.fill();
  };

  const getToothColor = (tooth) => {
    return selectedBridges.includes(tooth) || selectedBridges.includes(tooth - 1) ? bridgeColor : unitColor
  }

  const draw = (ctx) => {
    if (!ctx){
      return
    }

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.drawImage(
      archImage,
      0,
      offset,
      307,
      clipSize,
      extraWidth / 2,
      extraWidth / 2,
      canvasWidth - extraWidth,
      (clipSize * size) / 480
    );
    highlightedTeeth.forEach((t) => {
      const { tooth, color } = typeof t === "object" ? t : { tooth: t, color: getToothColor(t) };

      if (teeth[tooth]) {
        highligthTooth(ctx, teeth[tooth], color);
      }
    });


    availableBridges?.forEach((bridgeCount) => {
      const bridge = bridges[bridgeCount]

      if (bridge) {
        ctx.setLineDash([2, 2]);

        ctx.beginPath();
        ctx.arc(bridge.cx, bridge.cy, bridge.r, 0, 2 * Math.PI);
        ctx.closePath();
        if (highlightedBridges.includes(bridgeCount)){
          ctx.fillStyle = bridgeColor
          ctx.fill()
        } else {
          ctx.strokeStyle = bridgeColor
          ctx.stroke();
        }
      }
    });
  };

  const identifyTooth = (e) => {
    const rect = e.target?.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    for (var i in teeth) {
      const t = teeth[i];
      if (x >= t.x && y > t.y && x <= t.x + t.w && y <= t.y + t.h) {
        return parseInt(i);
      }
    }

    return null;
  };

  const filteredBridges = useMemo(() => {
    const result = {}

    availableBridges?.forEach(bridgeNum => {
      if (bridges[bridgeNum]) {
        result[bridgeNum] = bridges[bridgeNum]
      }
    })

    return result
  }, [bridges, availableBridges])

  const identifyBridge = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    for (const i in filteredBridges) {
      const b = filteredBridges[i];

      if (
        x >= b.x &&
        y > b.y &&
        x <= b.x + b.r * 2 &&
        y <= b.y + b.r * 2
      ) {
        return parseInt(i);
      }
    }

    return null;
  };

  const onMouseMove = function (e) {
    onHover(identifyTooth(e));
    onBridgeHover?.(identifyBridge(e))
  };

  const onMouseClick = function (e) {
    const tooth = identifyTooth(e);
    if (tooth !== null) {
      onClick(tooth);
    }

    if (onBridgeClick) {
      const bridge = identifyBridge(e);

      if (bridge) {
        onBridgeClick(bridge)
      }
    }
  };

  return (
    <Canvas
      draw={draw}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseClick}
      onMouseLeave={() => onHover(null)}
      width={canvasWidth}
      height={canvasHeight}
    />
  );
}
