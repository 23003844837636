import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ToothNumberSelect from "./ToothNumberSelect";
import ToothPicker from "./ToothPicker";

export default function SingleToothPicker(props) {
  const { jaw, value, onChange } = props;
  const { t } = useTranslation();

  const [hover, setHover] = useState(null);

  const handleChange = (tooth) => {
    onChange(tooth === value ? null : tooth);
  };

  const highlightedTeeth = [value, hover].filter((x) => x !== null);

  return (
    <>
      <Box sx={{ marginTop: "10px" }}>
        <Grid container alignItems="center">
          <Grid size="grow">
            <ToothPicker
              size={350}
              highlightedTeeth={highlightedTeeth}
              onClick={handleChange}
              onHover={setHover}
              jaw={jaw}
            />
          </Grid>
          <Grid size="grow">
            <div style={{ padding: "10px", minWidth: "130px" }}>
              <ToothNumberSelect
                label={t("Tooth")}
                value={value !== null ? value : ""}
                onChange={handleChange}
                jaw={jaw}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
