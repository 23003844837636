import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import React from "react";
import { useTranslation } from "react-i18next";
import { useGetResourceUtilizationQuery } from "../../services/organizations";
import Loading from "../Loading";
import LoadingError from "../system/LoadingError";

export default function ResourceUtilization({ organizationId }) {
  const { t } = useTranslation();
  const { data, error, isLoading } =
    useGetResourceUtilizationQuery(organizationId);

  if (isLoading) return <Loading />;
  else if (error) return <LoadingError error={error} />;

  const jobStat = (job) =>
    data.jobs?.[job]?.count
      ? `${data.jobs?.[job]?.count} (${Math.round(
          data.jobs?.[job]?.duration / 60
        )} ${t("minutes")})`
      : "--";

  return (
    <>
      <Typography variant="h6">{t("Resource Utilization")}</Typography>
      <Grid container>
        <Grid size={4}>
          {t("Projects")}:
        </Grid>
        <Grid size={8}>
          {data.projects ?? "--"}
        </Grid>
        <Grid size={4}>
          {t("Files")}:
        </Grid>
        <Grid size={8}>
          {data.files?.count
            ? `${data.files?.count} (${Math.round(
                data.files?.totalSize / 1024 / 1024
              )} ${t("MB")})`
            : "--"}
        </Grid>
        <Grid size={4}>
          {t("AI engine executions")}:
        </Grid>
        <Grid size={8}></Grid>
        <Grid size={4} paddingLeft={"2ch"}>
          {t("Tooth labeling")}:
        </Grid>
        <Grid size={8}>
          {jobStat("segmentation")}
        </Grid>
        <Grid size={4} paddingLeft={"2ch"}>
          {t("Margin line")}:
        </Grid>
        <Grid size={8}>
          {jobStat("marginline")}
        </Grid>
        <Grid size={4} paddingLeft={"2ch"}>
          {t("Crown generation")}:
        </Grid>
        <Grid size={8}>
          {jobStat("crownGeneration")}
        </Grid>
      </Grid>
    </>
  );
}
