import { useEffect } from "react";
import { useAuth } from "../../providers/authProvider";

export default function Logout() {
  const { signinSilentCallback } = useAuth();

  useEffect(() => {
    signinSilentCallback()
  }, [])

  return <>silent renew</>;
}
