import React from 'react'
import { Box } from '@mui/material'

export const DarkBox = ({ children }) => {
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      background: '#394d64',
      color: '#fff',
      width: '100%',
      minHeight: '400px',
      padding: '1.25rem',
    }}>
      <div>
        {children}
      </div>
    </Box>
  )
}
