import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { Button, CircularProgress, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { useAuth } from '../../providers/authProvider'
import {
  browserDownload,
  downloadFile,
  mediastoreUrl,
  zipAndDownload,
} from '../../services/FileService'

const useStyles = makeStyles({
  buttonNoUppercase: {
    textTransform: 'none',
    fontSize: '1rem',
  },
})

export default function FileDownload({
  projectId,
  file,
  files,
  downloadFilename,
  enabled = true,
  children,
  type,
  variant,
  sx,
}) {
  const classes = useStyles();
  const auth = useAuth();
  const [processing, setProcessing] = useState(false);

  if ((file && files) || (!file && !files)) {
    throw new Error('Either \'file\' or \'files\' must be set; and not both')
  }

  async function download() {
    if (processing) return

    const token = (await auth.getUser()).access_token
    if (file) {
      browserDownload(
        mediastoreUrl(projectId, `${file}/download`),
        downloadFilename,
        token,
      )
    } else if (files) {
      setProcessing(true)
      zipAndDownload(
        files.map(({ id, name }) => ({
          name,
          data: downloadFile(projectId, id, token),
        })),
        downloadFilename,
      )
      setProcessing(false)
    }
  }

  if (type === 'icon') {
    return (
      <IconButton
        disabled={!enabled}
        onClick={download}
        sx={{ ...sx }}
        className={`ui-file-download`}
        edge="end"
        color="primary"
      >
        <FileDownloadIcon />
      </IconButton>
    )
  }

  return (
    <Button
      disabled={!enabled}
      startIcon={
        processing ? <CircularProgress size={20} /> : <FileDownloadIcon />
      }
      onClick={download}
      sx={{ flexGrow: 1, justifyContent: 'left', ...sx }}
      className={`ui-file-download ${classes.buttonNoUppercase}`}
      variant={variant}
    >
      {children || downloadFilename}
    </Button>
  )
}
