import { Box, Button } from '@mui/material'
import Grid from "@mui/material/Grid2";
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ToothPicker from './ToothPicker'
import ToothSettings from './ToothSettings'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

export default function MultiToothPicker(props) {
  const { t } = useTranslation();
  const { jaw, selectedTeeth, teethSettings, onChange, showClear } = props;

  const [hover, setHover] = useState(null);
  const [bridgeHover, setBridgeHover] = useState(null);
  const [selectedBridges, setSelectedBridges] = useState([])

  const toggle = (tooth) => {
    let updatedTeethSettings = {};
    if (tooth in teethSettings) {
      Object.keys(teethSettings).map((key) => {
        (key !== String(tooth)) && (updatedTeethSettings[key] = teethSettings[key]);
      });
      setSelectedBridges(prev => prev.filter((item) => item !== tooth && item !== tooth - 1))
    } else {
      updatedTeethSettings = {...teethSettings, [tooth]: {}};
    }

    onChange({
      teethUnderRepair: Object.keys(updatedTeethSettings).map(Number),
      teethSettings: updatedTeethSettings,
    });
  };

  const changeType = (tooth, type) => {
    const updatedTeethSettings = {...teethSettings};

    updatedTeethSettings[tooth] = {...(updatedTeethSettings[tooth] ?? {}), type};

    onChange({
      teethSettings: updatedTeethSettings,
    });
  };

  const toggleBridge = (bridge) => {
    if (selectedBridges.indexOf(bridge) !== -1) {
      setSelectedBridges(prev => prev.filter((item) => item !== bridge))
    } else {
      setSelectedBridges(prev => [...prev, bridge])
    }
  }

  const availableBridges = useMemo(() => {
    const bridgesNumbers = []

    const sortedSelectedTeeth = [...selectedTeeth].sort((a, b) => a - b)

    sortedSelectedTeeth.forEach((number, index, array) => {
      if (index === 0) {
        return
      }

      const prevNumber = array[index - 1]

      if (number - prevNumber === 1) {
        bridgesNumbers.push(prevNumber)
      }
    })

    return bridgesNumbers
  }, [selectedTeeth])

  const highlightedTeeth = [...selectedTeeth, hover].filter((x) => x !== null);
  const highlightedBridges = [...selectedBridges, bridgeHover].filter((x) => x !== null);

  return (
    <>
      <Box className="ui-tooth-picker">
        <Grid container alignItems="flex-start" columnSpacing={2}>
          <Grid>
            <ToothPicker
              size={350}
              highlightedTeeth={highlightedTeeth}
              availableBridges={availableBridges}
              onClick={toggle}
              onHover={setHover}
              highlightedBridges={highlightedBridges}
              selectedBridges={selectedBridges}
              onBridgeClick={toggleBridge}
              onBridgeHover={setBridgeHover}
              jaw={jaw}
            />
            <Stack sx={{ gap: 1, mt: 2 }}>
              <Stack direction="row" sx={{ gap: '0.5rem', alignItems: 'center' }}>
                <Box sx={{ width: 20, aspectRatio: 1, borderRadius: '50%', background: '#95cdf7' }} />
                <Typography variant="subtitle2">Single unit</Typography>
              </Stack>
              <Stack direction="row" sx={{ gap: '0.5rem', alignItems: 'center' }}>
                <Box sx={{ width: 20, aspectRatio: 1, borderRadius: '50%', background: '#ee7fb2' }} />
                <Typography variant="subtitle2">Bridge</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            size="grow"
            container
            direction="row"
            alignItems="flex-end"
            justifyItems="stretch"
          >
            <Grid size="grow">
              <Box sx={{ marginBottom: '2.5rem' }}>
                <ToothSettings
                  selectedTeeth={selectedTeeth}
                  selectedBridges={selectedBridges}
                  teethSettings={teethSettings}
                  jaw="upper"
                  onChangeType={changeType}
                  onDelete={toggle}
                />
              </Box>
              <ToothSettings
                selectedTeeth={selectedTeeth}
                selectedBridges={selectedBridges}
                teethSettings={teethSettings}
                jaw="lower"
                onChangeType={changeType}
                onDelete={toggle}
              />
            </Grid>
            <Grid marginLeft={"auto"}>
              {showClear ? (
                <Button
                  disabled={selectedTeeth.length === 0}
                  onClick={() => onChange([])}
                >
                  {t("Clear")}
                </Button>
              ) : (
                <>&nbsp;</>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
