import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, LinearProgress } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Done } from '@mui/icons-material'
import { DarkBox } from './DarkBox'
import Stack from '@mui/material/Stack'

const ProgressIcon = (props) => {
  const { currentStep, step } = props
  if (step === currentStep) {
    return <CircularProgress size="1.5rem" sx={{ color: '#fff' }} />
  }

  if (step < currentStep) {
    return <Done color="success" />
  }

  return <Box sx={{ width: '1.5rem', aspectRatio: 1 }} />
}

export const FakeProgressBar = ({ actions }) => {
  const [progress, setProgress] = useState(1)
  const [step, setStep] = useState(1)

  useEffect(() => {
    let min = 3
    let max = 7

    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 97) {
          return 98.5
        }

        if (oldProgress >= 85) {
          min = 0
          max = 2
        } else if (oldProgress >= 70) {
          setStep(3)
        } else if (oldProgress >= 45) {
          setStep(2)
        } else if (oldProgress >= 21) {
          min = 1
          max = 5
        }

        const diff = Math.random() * (max - min) + min
        return Math.min(oldProgress + diff, 100)
      })
    }, 1600)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <DarkBox>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.75rem',
      }}>
        <Typography variant="body2">We are generating a margin line. It may take up to 60 seconds</Typography>
        <LinearProgress variant="determinate" value={progress} sx={{
          width: '410px',
          height: 8,
          borderRadius: '4px',
        }} />
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.675rem',

            '& > div': {
              display: 'flex',
              alignItems: 'center',
              gap: '0.75rem',
              fontSize: '0.75rem',
            },
          }}>
            <Box>
              <ProgressIcon currentStep={step} step={1} />
              AI generation
            </Box>
            <Box>
              <ProgressIcon currentStep={step} step={2} />
              Spline preparation
            </Box>
            <Box>
              <ProgressIcon currentStep={step} step={3} />
              Finalizing margin line
            </Box>
          </Box>
        </Box>
        {actions && <Stack direction="row" spacing={2}>{actions}</Stack>}
      </Box>
    </DarkBox>
  )
}
