import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useProjectApi } from '../../providers/ProjectApiProvider';
import { useSettings } from '../../providers/UserProfileProvider';
import { getToothLabel } from '../../utils/ToothNotation';
import FeedbackWidget from '../widgets/FeedbackWidget';
import CompletedProjectTutorial from '../tutorial/CompletedProjectTutorial';
import FileDownload from './FileDownload';
import React, { useState } from 'react';
import ProjectFilesViewer from './ProjectFilesViewer';
import useVisibilities from "../../hooks/useVisibilities";

function FeedbackForm() {
  const { project, saveProject } = useProjectApi();
  const { t } = useTranslation();
  const [feedbackValue, setFeedbackValue] = useState(project.feedback ?? null);
  const [feedbackComment, setFeedbackComment_] = useState(project.feedbackComment ?? '');

  const setFeedbackComment = _.debounce(setFeedbackComment_, 150);

  function submitFeedback() {
    saveProject({
      feedback: feedbackValue,
      feedbackComment,
    });
  }

  const isSubmitted = !!project.feedback;
  const isSubmitEnabled = ((project.feedbackComment ?? '') !== feedbackComment) || ((project.feedback ?? null) !== feedbackValue);

  return (
    <>
      <FeedbackWidget value={feedbackValue} onChange={setFeedbackValue} />
      {feedbackValue !== null &&
        (
          <Box>
            {isSubmitted ? (
              <Alert color="success" sx={{ mt: '1rem' }}>
                {t('Thanks! To update your feedback, choose a new reaction or edit your comment, then click "Send Feedback."')}
              </Alert>
            ) : null}
            <TextField
              label={t('Comment (optional)')}
              fullWidth
              multiline
              sx={{
                mb: '1rem',
                mt: '1rem',
              }}
              defaultValue={project.feedbackComment}
              onChange={(e) => setFeedbackComment(e.target.value)}
            />
            <Button
              variant="contained"
              size="small"
              color="secondary"
              sx={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 0,
              }}
              onClick={submitFeedback}
              disabled={!isSubmitEnabled}
            >
              {t('Send Feedback')}
            </Button>
          </Box>
        )}
    </>
  )
}

export default function CompletedProject() {
  const { notation } = useSettings();
  const { project } = useProjectApi();
  const { t } = useTranslation();

  const { isVisible, setVisible } = useVisibilities({
    upperArch: project.upperArch.file.isDecimated(),
    lowerArch: project.lowerArch.file.isDecimated(),
    ...Object.fromEntries(
      Object.keys(project.toothRestorations).map((t) => [`crown${t}`, true])
    ),
    ...Object.fromEntries(
      Object.keys(project.toothRestorations).map((t) => [`marginLine${t}`, true])
    ),
  });

  return (
    <>
      <CompletedProjectTutorial />
      <Grid container spacing={5}>
        <Grid size={6}>
          <Typography variant="h4" gutterBottom>
            {t('Success')}
          </Typography>
          <Typography sx={{ mb: 2 }}>{t('Dental design project complete.')}</Typography>
          <Box sx={{ border: '1px solid rgb(0 0 0 / 23%)',  marginBottom: '2.5rem' }}>
            <Box sx={{ padding: '0.5rem 1rem', background: 'rgb(0 0 0 / 10%)' }}>
              <Typography variant="h6">Generated crowns</Typography>
            </Box>
            <Box sx={{ padding: '0.75rem 1rem' }}>
              <List sx={{ mb: 3 }}>
                {Object.values(project.toothRestorations).map((restoration) => (
                  <React.Fragment key={project.id}>
                    <ListItem
                      sx={{ padding: '0.375rem 0.75rem' }}
                      key={restoration.tooth}
                      secondaryAction={
                        <FileDownload
                          projectId={project.id}
                          file={restoration.crownFileName()}
                          downloadFilename={`crown${getToothLabel(restoration.tooth, notation)}.stl`}
                          type="icon"
                        />
                      }
                    >
                      <ListItemText
                        primary={`Crown_${getToothLabel(restoration.tooth, notation)}.stl`}
                      />
                    </ListItem>
                    <Divider component="li" />
                  </React.Fragment>
                ))}
              </List>
              {Object.values(project.toothRestorations).length > 1
                ?
                  <FileDownload
                    projectId={project.id}
                    files={Object.values(project.toothRestorations).map((t) => ({
                      id: t.crownFileName(),
                      name: `crown${getToothLabel(t.tooth, notation)}.stl`,
                    }))}
                    downloadFilename={`${project.refNumber}.zip`}
                    variant="contained"
                  >
                    {t('Download all crowns')}
                  </FileDownload>
                : null}
            </Box>
          </Box>
          <Card>
            <CardHeader title={t('projects.feedbackRequest')} />
            <CardContent>
              <FeedbackForm />
            </CardContent>
          </Card>
        </Grid>
        <Grid size={6}>
          <Box className="ui-crowns-preview">
            <ProjectFilesViewer isVisible={isVisible} />
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
