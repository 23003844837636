import Grid from "@mui/material/Grid2";
import { useState } from "react";
import { useProfile } from "../../providers/UserProfileProvider";
import OrganizationMenu from "./OrganizationMenu";
import OrganizationTitle from "./OrganizationTitle";

function OrganizationHeader() {
  const [nameEdit, setNameEdit] = useState(false);
  const { isAdmin, organization } = useProfile();

  return (
    <Grid container>
      <Grid size="grow">
        <OrganizationTitle
          edit={nameEdit}
          onClick={() => setNameEdit(true)}
          onDoneEditing={() => setNameEdit(false)}
        />
      </Grid>
      <Grid>
        {(isAdmin || organization.role == "manager") && (
          <OrganizationMenu onRename={() => setNameEdit(true)} />
        )}
      </Grid>
    </Grid>
  );
}

export default OrganizationHeader;
