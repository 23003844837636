import { useEffect } from "react";
import { useAuth } from "../../providers/authProvider";
import Loading from "../Loading";

export default function Logout() {
  const { logout } = useAuth();

  useEffect(() => {
    logout()
  }, [])

  return <Loading />;
}
