import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import config from "../../config";
import {useProjectApi} from "../../providers/ProjectApiProvider";
import { TeethTabs } from '../widgets/TeethTabs';
import { TabPanel } from '../widgets/TabPanel';
import {useState, useMemo} from "react";

export default function CrownEvaluation() {
  const {
    project: {
      id: projectId,
      toothRestorations,
    },
  } = useProjectApi();

  const restorations = useMemo(() => {
    return Object.values(toothRestorations)
  }, [toothRestorations]);

  const [value, setValue] = useState(restorations[0]?.tooth);

  const changeTab = (event, newValue) => {
    setValue(newValue);
  };

  const createUrl = (projectId, toothNumber) => {
    return `${config.crownEvaluationUrl}?project_id=${encodeURIComponent(projectId)}&tooth_number=${encodeURIComponent(toothNumber)}`;
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid>
        <TeethTabs data={restorations} tabTitle="Crown" value={value} onChange={changeTab} />
      </Grid>
      <Grid sx={{ flex: 1, minWidth: 0 }}>
        {restorations.map((restoration) => (
          <TabPanel activeTab={value} key={restoration.tooth} value={restoration.tooth}>
            <Box className="ui-scans-preview" justifyItems={"center"}>
              <iframe src={createUrl(projectId, restoration.tooth)} width={900} height={600} style={{border: 0}} />
            </Box>
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
}
