import { PersonRemove } from "@mui/icons-material";
import {
  Chip,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Gravatar from "react-gravatar";
import { useTranslation } from "react-i18next";
import { useConfirmationDialog } from "../../providers/ConfirmationDialogProvider";
import { useOrganizationApi } from "../../providers/OrganizationApiProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { useProfile } from "../../providers/UserProfileProvider";
import UserInviter from "../widgets/UserInviter";
import UserPicker from "../widgets/UserPicker";

export default function OrganizationMembers() {
  const {
    organization: { members },
    addOrganizationMember,
    updateOrganizationMember,
    deleteOrganizationMember,
  } = useOrganizationApi();
  const { showConfirmationDialog } = useConfirmationDialog();
  const { user, isAdmin, organization } = useProfile();
  const { showAlert } = useSnackbar();
  const { t } = useTranslation();

  const handleRemoveMember = async (member) => {
    const mesg = t("organizations.removeUserFromOrganization", {
      user: member.name || member.email,
    });
    if (await showConfirmationDialog(mesg)) {
      deleteOrganizationMember(member.id);
    }
  };

  const addMember = async ({ sub, name, email }) => {
    if (members.map((m) => m.email).includes(email)) {
      showAlert(t("User is already a member"), "info");
      return false;
    }

    try {
      await addOrganizationMember({ sub, name, email });
      return true;
    } catch (e) {
      return false;
    }
  };

  const updateUserRole = (id, newRole) =>
    updateOrganizationMember(id, { role: newRole });

  return (
    <>
      <div className="ui-user-picker">
        {isAdmin ? (
          <UserPicker onUserPicked={addMember} />
        ) : (
          organization.role === "manager" && (
            <UserInviter onUserPicked={addMember} />
          )
        )}
      </div>
      <List dense>
        {members.map((member) => (
          <ListItem key={member.id} secondaryAction={
            <Box display="flex" alignItems="center">
              <FormControl size="small" sx={{ width: "15ch", mr: 2 }}>
                {isAdmin || organization.role === "manager" ? (
                  <Select
                    variant="standard"
                    value={member.role || "user"}
                    onChange={(event) =>
                      updateUserRole(member.id, event.target.value)
                    }
                    disabled={!isAdmin && user.sub == member.sub}
                  >
                    <MenuItem value={"user"}>{t("User")}</MenuItem>
                    <MenuItem value={"manager"}>{t("Manager")}</MenuItem>
                  </Select>
                ) : (
                  <TextField
                    variant="standard"
                    inputProps={{ readOnly: true }}
                    value={member.role === "manager" ? "Manager" : "User"}
                  />
                )}
              </FormControl>
              {(isAdmin || organization.role === "manager") && (
                <IconButton
                  onClick={() => handleRemoveMember(member)}
                  disabled={!isAdmin && user.sub == member.sub}
                >
                  <PersonRemove />
                </IconButton>
              )}
            </Box>
          }>
            <ListItemAvatar>
              <Gravatar
                email={member.email}
                size={40}
                style={{ borderRadius: 20, marginRight: "10px" }}
              ></Gravatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                member.sub ? (
                  member.name
                ) : (
                  <Chip label="Invitation sent" size="small" />
                )
              }
              secondary={member.email}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
}
