import { Button, CircularProgress, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import _ from "lodash";
import { useRef, useState } from "react";
import isEmail from "validator/lib/isEmail";

export default function UserInviter({ onUserPicked }) {
  const inputRef = useRef();
  const [addInProgress, setAddInProgress] = useState(false);
  const [okToAdd, setOkToAdd] = useState(false);

  const filter = _.debounce((value) => {
    setOkToAdd(isEmail(value));
  }, 100);

  const pickUser = async () => {
    setAddInProgress(true);
    try {
      if (await onUserPicked({ email: inputRef.current.value })) {
        inputRef.current.value = "";
      }
    } finally {
      setAddInProgress(false);
    }
  };

  return (
    <Grid container spacing={1} margin={1}>
      <Grid>
        <TextField
          disabled={addInProgress}
          variant="outlined"
          size="small"
          sx={{ width: "40ch" }}
          label="Email address"
          onChange={(e) => filter(e.target.value)}
          onKeyDown={(e) => {
            if (e.key == "Enter" && okToAdd) pickUser();
          }}
          inputRef={inputRef}
        />
      </Grid>
      <Grid alignItems="center" style={{ display: "flex" }}>
        <Button
          variant="contained"
          disabled={!okToAdd || addInProgress}
          onClick={pickUser}
        >
          Invite
        </Button>
      </Grid>
      <Grid alignItems="center" style={{ display: "flex" }}>
        {addInProgress ? (
          <CircularProgress size={24}></CircularProgress>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
}
