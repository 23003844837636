import { useEffect } from 'react'
import { useAuth } from '../../providers/authProvider'
import Loading from '../Loading'

export default function LoginCallback() {
  const { signinRedirectCallback } = useAuth()
  useEffect(() => {
      signinRedirectCallback().catch(() => (window.location = "/"))
  }, [])
  return <Loading />
}
