import { Box, FormHelperText } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ToothNumberSelect from "./ToothNumberSelect";
import ToothPicker from "./ToothPicker";
import _ from "lodash";

export default function ArchExtentsPicker(props) {
  const { jaw, arch, onChange, preppedTeeth } = props;
  const { t } = useTranslation();

  const [hover, setHover] = useState(null);

  function setLabel1(newVal) {
    onChange({ start: newVal });
  }
  function setLabel2(newVal) {
    onChange({ end: newVal });
  }

  const onToothSelect = function (tooth) {
    if (arch.start !== null) {
      if ((arch.start <= 16 && tooth > 16) || (arch.start > 16 && tooth <= 16))
        return;
    }

    if (tooth === arch.end) {
      setLabel2(null);
    } else if (tooth === arch.start) {
      onChange({ start: null, end: null });
    } else if (arch.start === null) {
      setLabel1(tooth);
    } else if (arch.end === null) {
      setLabel2(tooth);
    } else if (
      arch.end - arch.start > 0 &&
      tooth < arch.start &&
      tooth < arch.end
    ) {
      setLabel1(tooth);
    } else if (
      arch.start - arch.end > 0 &&
      tooth > arch.start &&
      tooth > arch.end
    ) {
      setLabel1(tooth);
    } else {
      setLabel2(tooth);
    }
  };

  const highlightedTeeth = (() => {
    if (arch.start !== null) {
      if (arch.end !== null) {
        return [...Array(Math.abs(arch.start - arch.end) + 1).keys()].map(
          (x) => x + Math.min(arch.start, arch.end)
        );
      } else if (hover !== null) {
        if (
          (arch.start <= 16 && hover > 16) ||
          (arch.start > 16 && hover <= 16)
        )
          return [arch.start];

        return [...Array(Math.abs(arch.start - hover) + 1).keys()].map(
          (x) => x + Math.min(arch.start, hover)
        );
      }
      return [arch.start];
    } else if (hover !== null) {
      return [hover];
    }
    return [];
  })();

  const archIsMissingPreppedTooth =
    !!arch.start && !!arch.end && !arch.coversAllApplicableTeeth(preppedTeeth);

  const preppedTeethHighlights = _.intersection(preppedTeeth, arch.teeth()).map(
    (tooth) => ({
      tooth,
      color: "#9c27b0",
    })
  );

  return (
    <>
      <Box sx={{ marginTop: "10px" }}>
        <Grid container alignItems="center">
          <Grid size="grow">
            <ToothPicker
              size={300}
              highlightedTeeth={[
                ...highlightedTeeth,
                ...preppedTeethHighlights,
              ]}
              onClick={onToothSelect}
              onHover={setHover}
              jaw={jaw}
            />
          </Grid>
          <Grid size="grow">
            <div style={{ padding: "10px" }}>
              <ToothNumberSelect
                error={archIsMissingPreppedTooth}
                label={t("First tooth")}
                value={arch.start !== null ? arch.start : ""}
                onChange={setLabel1}
                jaw={jaw}
              />
            </div>
            <div style={{ padding: "10px" }}>
              <ToothNumberSelect
                error={archIsMissingPreppedTooth}
                label={t("Last tooth")}
                value={arch.end !== null ? arch.end : ""}
                onChange={setLabel2}
                jaw={jaw}
              />
            </div>
          </Grid>
        </Grid>
        {archIsMissingPreppedTooth && (
          <FormHelperText error sx={{ textAlign: "center" }}>
            {t("Arch must include prepped tooth/teeth")}
          </FormHelperText>
        )}
      </Box>
    </>
  );
}
