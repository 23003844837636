import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useProjectApi } from "../../providers/ProjectApiProvider";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { startSegmentation } from "../../services/apiService";
import { mediastoreUrl } from "../../services/FileService";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "../widgets/Accordion";
import MeshViewer from "../widgets/MeshViewer";
import StatusIcon from "../widgets/StatusIcon";
import ArchRange from "./ArchRange";
import FileDownload from "./FileDownload";
import SegmentationLegend from "./SegmentationLegend";

export default function Segmentation() {
  const { showAlert } = useSnackbar();
  const [expanded, setExpanded] = useState("upper");
  const { t } = useTranslation();
  const handleChange = (panel) => (_, newExpanded) => {
    setExpanded(newExpanded ? panel : null);
  };

  const { id: projectId, upperArch, lowerArch } = useProjectApi().project;

  const previewFile =
    expanded === "upper" && upperArch.segmentation?.status === "success"
      ? mediastoreUrl(projectId, upperArch.segmentedVisualizationFileName())
      : expanded === "lower" && lowerArch.segmentation?.status === "success"
      ? mediastoreUrl(projectId, lowerArch.segmentedVisualizationFileName())
      : null;

  const doStartSegmentation = () =>
    startSegmentation(projectId).catch(() =>
      showAlert(t("Failed to initiate tooth labeling"))
    );

  useEffect(() => {
    if (
      (!upperArch.segmentation && upperArch.isReady()) ||
      (!lowerArch.segmentation && lowerArch.isReady())
    )
      doStartSegmentation();
  }, []);

  return (
    <>
      <Grid container sx={{ marginLeft: "0px" }}>
        <Grid container size={5} direction="column">
          <Grid size="grow" maxWidth={"100%"}>
            <Box>
              <Accordion
                expanded={expanded === "upper"}
                onChange={handleChange("upper")}
              >
                <AccordionSummary>
                  <Typography
                    sx={{
                      marginRight: "auto",
                      fontWeight: expanded === "upper" ? 700 : 400,
                    }}
                  >
                    {t("Upper arch")}
                  </Typography>
                  <ArchRange arch={upperArch} />
                  <StatusIcon status={upperArch.segmentation} />
                </AccordionSummary>
                <AccordionDetails>
                  {upperArch.segmentation ? (
                    <FileDownload
                      projectId={projectId}
                      file={upperArch.segmentedVisualizationFileName()}
                      downloadFilename={upperArch.segmentedVisualizationFileDownloadName()}
                      enabled={upperArch.segmentation?.status === "success"}
                      sx={{ maxWidth: "350px", overflow: "hidden" }}
                    />
                  ) : (
                    <Typography sx={{ fontStyle: "italic" }}>
                      {t("(incomplete data)")}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "lower"}
                onChange={handleChange("lower")}
              >
                <AccordionSummary>
                  <Typography
                    sx={{
                      marginRight: "auto",
                      fontWeight: expanded === "lower" ? 700 : 400,
                    }}
                  >
                    {t("Lower arch")}
                  </Typography>
                  <ArchRange arch={lowerArch} />
                  <StatusIcon status={lowerArch.segmentation} />
                </AccordionSummary>
                <AccordionDetails>
                  {lowerArch.segmentation ? (
                    <FileDownload
                      projectId={projectId}
                      file={lowerArch.segmentedVisualizationFileName()}
                      downloadFilename={lowerArch.segmentedVisualizationFileDownloadName()}
                      enabled={lowerArch.segmentation?.status === "success"}
                    />
                  ) : (
                    <Typography sx={{ fontStyle: "italic" }}>
                      {t("(incomplete data)")}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Grid>
          <Grid>
            <Box
              xs={{
                justifyContent: "center",
              }}
            >
              <SegmentationLegend
                arch={
                  expanded === null
                    ? "none"
                    : expanded == "upper"
                    ? "upper"
                    : "lower"
                }
              />
            </Box>
          </Grid>
        </Grid>
        <Grid size={7}>
          <Box marginLeft={3}>
            <MeshViewer
              width="445px"
              height="445px"
              files={
                previewFile != null ? [{ name: previewFile, show: true }] : []
              }
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
