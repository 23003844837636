import Arch from "./Arch";
import ProjectState from "./ProjectState";
import ToothRestoration from "./ToothRestoration";

export class ToothType {
  static NATURAL_TOOTH = 'naturalTooth';
  static IMPLANT = 'implant';
  static PONTIC = 'pontic';
}

export default class Project {
  constructor(obj) {
    let data = {
      refNumber: null,
      additionalInfo: null,
      teethUnderRepair: [],
      teethSettings: {},
      upperArch: new Arch("upper"),
      lowerArch: new Arch("lower"),
      toothRestorations: {},
      state: ProjectState.NEW,
      createdAt: null,
      updatedAt: null,
      feedback: null,
      dataUsageConsent: true,
      workerVersions: {},
    };
    Object.defineProperties(this, {
      id: {
        get: () => data.id,
        enumerable: true,
      },
      refNumber: {
        get: () => data.refNumber,
        enumerable: true,
      },
      additionalInfo: {
        get: () => data.additionalInfo,
        enumerable: true,
      },
      teethUnderRepair: {
        get: () => data.teethUnderRepair,
        enumerable: true,
      },
      teethSettings: {
        get: () => data.teethSettings,
        enumerable: true,
      },
      upperArch: {
        get: () => data.upperArch,
        set: (x) => (data.upperArch = x),
        enumerable: true,
      },
      lowerArch: {
        get: () => data.lowerArch,
        set: (x) => (data.lowerArch = x),
        enumerable: true,
      },
      toothRestorations: {
        get: () => data.toothRestorations,
        enumerable: true,
      },
      state: {
        get: () => data.state,
        enumerable: true,
      },
      createdAt: {
        get: () => data.createdAt,
        enumerable: true,
      },
      updatedAt: {
        get: () => data.updatedAt,
        enumerable: true,
      },
      feedback: {
        get: () => data.feedback,
        enumerable: true,
      },
      feedbackComment: {
        get: () => data.feedbackComment,
        enumerable: true,
      },
      dataUsageConsent: {
        get: () => data.dataUsageConsent,
        enumerable: true,
      },
      ownerOrganization: {
        get: () => data.ownerOrganization,
        enumerable: true,
      },
      ownerUser: {
        get: () => data.ownerUser,
        enumerable: true,
      },
      workerVersions: {
        get: () => data.workerVersions,
        enumerable: true,
      },
      raw: {
        get: () => ({
          ...data,
          upperArch: data.upperArch.raw,
          lowerArch: data.lowerArch.raw,
          toothRestorations: Object.fromEntries(
            Object.entries(data.toothRestorations).map(([k, v]) => [k, v.raw])
          ),
          state: data.state.toJSON(),
          createdAt: data.createdAt ? data.createdAt.toISOString() : null,
          updatedAt: data.updatedAt ? data.updatedAt.toISOString() : null,
        }),
      },
    });

    function syncToothRestorations() {
      const restorations =
        obj && obj.teethUnderRepair
          ? Object.fromEntries(
              obj.teethUnderRepair.map(Number).map((t) => [
                t,
                new ToothRestoration(
                  obj.toothRestorations && t in obj.toothRestorations
                    ? { ...obj.toothRestorations[t], tooth: t }
                    : t
                ),
              ])
            )
          : {};
      return restorations;
    }

    function convertState(state, previousState) {
      if (state === "abandonned" && previousState)
        return ProjectState.valueOf(previousState);
      return state ? ProjectState.valueOf(state) : ProjectState.NEW;
    }

    Object.assign(data, {
      ...obj,
      upperArch: new Arch(obj ? { ...obj.upperArch, jaw: "upper" } : "upper"),
      lowerArch: new Arch(obj ? { ...obj.lowerArch, jaw: "lower" } : "lower"),
      toothRestorations: syncToothRestorations(),
      state: convertState(obj?.state, obj?.previousState),
      createdAt: obj && obj.createdAt ? new Date(obj.createdAt) : null,
      updatedAt: obj && obj.updatedAt ? new Date(obj.updatedAt) : null,
    });
    this.upperArch.file.id = "upper";
    this.lowerArch.file.id = "lower";
  }

  toJSON() {
    return this.raw;
  }

  isReadyForScans() {
    const teethSettings = Object.values(this.teethSettings);

    return this.teethUnderRepair.length &&
      this.refNumber &&
      teethSettings.length &&
      teethSettings.every((item) => !!item?.type);
  }

  isReadyForMarginLineGeneration() {
    const isReady = (arch) =>
      (this.isAntagonist(arch) && arch.isBlank()) ||
      (arch.isReady() && arch.coversAllApplicableTeeth(this.teethUnderRepair));

    return (
      isReady(this.upperArch) &&
      isReady(this.lowerArch) &&
      Object.values(this.toothRestorations)
        .map((t) => t.dieFile)
        .every((f) => !f.isSet() || f.isDecimated())
    );
  }

  isReadyForPrepEvaluation() {
    return Object.values(this.toothRestorations).every((item) => item.marginLine?.status === 'success');
  }

  isReadyForCrownGeneration() {
    const isReady = (arch) =>
      (this.isAntagonist(arch) && arch.isBlank()) ||
      (arch.isReady() && arch.coversAllApplicableTeeth(this.teethUnderRepair));

    return (
      isReady(this.upperArch) &&
      isReady(this.lowerArch) &&
      Object.values(this.toothRestorations)
        .map((t) => t.dieFile)
        .every((f) => !f.isSet() || f.isDecimated())
    );
  }

  getFiles() {
    const result = [];
    if (this.upperArch.file.isSet())
      result.push(this.upperArch.file.rawFileName());
    if (this.upperArch.file.isDecimated())
      result.push(this.upperArch.file.decimatedFileName());
    if (this.upperArch.segmentation?.status === "success") {
      result.push(this.upperArch.segmentedFileName());
      result.push(this.upperArch.segmentedVisualizationFileName());
    }
    if (this.lowerArch.file.isSet())
      result.push(this.lowerArch.file.rawFileName());
    if (this.lowerArch.file.isDecimated())
      result.push(this.lowerArch.file.decimatedFileName());
    if (this.lowerArch.segmentation?.status === "success") {
      result.push(this.lowerArch.segmentedFileName());
      result.push(this.lowerArch.segmentedVisualizationFileName());
    }

    if (
      this.upperArch.segmentation?.status === "success" ||
      this.lowerArch.segmentation?.status === "success"
    ) {
      result.push("lower_registration.csv");
    }

    Object.values(this.toothRestorations).forEach((rest) => {
      if (rest.dieFile.isSet()) result.push(rest.dieFile.rawFileName());
      if (rest.dieFile.isDecimated())
        result.push(rest.dieFile.decimatedFileName());
      if (rest.marginLine?.status === "success") {
        result.push(rest.marginLineFileName());
      }
      if (rest.crownGeneration?.status === "success") {
        result.push(rest.crownFileName());
        result.push(rest.crownShellFileName());
        result.push(rest.crownBottomAndMarginlineFileName());
      }
    });
    return result;
  }

  isReadyForMarginLinePrediction(tooth) {
    const arch = tooth <= 16 ? this.upperArch : this.lowerArch;
    return (
      arch.isReady() &&
      !!this.toothRestorations[tooth] &&
      (!this.toothRestorations[tooth].dieFile.isSet() ||
        this.toothRestorations[tooth].dieFile.decimation?.status === "success")
    );
  }

  hasError() {
    return (
      this.lowerArch?.file?.decimation?.status === "failed" ||
      this.lowerArch?.segmentation?.status === "failed" ||
      this.upperArch?.file?.decimation?.status === "failed" ||
      this.upperArch?.segmentation?.status === "failed" ||
      Object.values(this.toothRestorations).some(
        (r) =>
          r.dieFile?.decimation?.status === "failed" ||
          r.marginLine?.status === "failed" ||
          r.crownGeneration?.status === "failed"
      )
    );
  }

  isAntagonist(arch) {
    const archTeeth = arch.teeth();
    return this.teethUnderRepair.every((t) => !archTeeth.includes(t));
  }
}
